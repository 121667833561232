const config = {
    clientURI: window.clientURI,
    customerURL: window.customerURL,
    apiURI: window.apiURI,
    publicApiURI: window.publicApiURI,
    registerURI: window.registerURI,
    sseURI: window.sseURI,
    statsURI: window.statsURI,
    interfaceLanguages: window.interfaceLanguages,
    playerBufferBar: window.playerBufferBar,
    languagePriorityOptions: window.languagePriorityOptions,
    audioPriorityCookieName: "_audio_priority",
    appLangCookieName: "_app_lang",
    termsURL_lt: `${window.customerURL}/cgates/legal/prod/CGates%20TV%20Paslaugos%20Teikimo%20S%C4%85lygos.txt`,
    privacyURL_lt:
        `${window.customerURL}/cgates/legal/prod/Privatumo%20(Slapuk%C5%B3)%20Politika%20ir%20Duomen%C5%B3%20Apsauga.txt`,
    cookiesURL_lt:
        `${window.customerURL}/cgates/legal/prod/slapuku_politika-lt.txt`,
    contactsURL_lt: `${window.customerURL}/cgates/legal/prod/kontaktai-lt.txt`,
    termsURL_en:
        `${window.customerURL}/cgates/legal/prod/CGates%20TV%20Paslaugos%20Teikimo%20S%C4%85lygos%20(EN).txt`,
    privacyURL_en:
        `${window.customerURL}/cgates/legal/prod/Privatumo%20(Slapuk%C5%B3)%20Politika%20ir%20Duomen%C5%B3%20Apsauga%20(EN).txt`,
    cookiesURL_en:
        `${window.customerURL}/cgates/legal/prod/slapuku_politika-en.txt`,
    contactsURL_en: `${window.customerURL}/cgates/legal/prod/kontaktai-en.txt`,
    termsURL_ru:
        `${window.customerURL}/cgates/legal/prod/CGates%20TV%20Paslaugos%20Teikimo%20S%C4%85lygos%20(RU).txt`,
    privacyURL_ru:
        `${window.customerURL}/cgates/legal/prod/Privatumo%20(Slapuk%C5%B3)%20Politika%20ir%20Duomen%C5%B3%20Apsauga%20(RU).txt`,
    cookiesURL_ru:
        `${window.customerURL}/cgates/legal/prod/slapuku_politika-ru.txt`,
    contactsURL_ru: `${window.customerURL}/cgates/legal/prod/kontaktai-ru.txt`,
};

export default config;
