import { format } from 'date-fns';
import { enGB, ru, lt } from 'date-fns/locale';

const locales = { enGB, ru, lt };

const localeFormat = (date, formatStr = 'PP') => {
    return format(date, formatStr, {
        locale: locales[window.__localeId__], // or global.__localeId__
    });
};

export default localeFormat;
