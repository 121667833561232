import React, { useEffect, useState } from 'react';
import FacebookIcon from 'img/facebook-icon.svg';
import InstagramIcon from 'img/instagram-icon.svg';
import { FormattedMessage } from 'react-intl';
import config from 'config/config';
import { useLocation } from 'react-router-dom';
import LegalInfoModal from './LegalInfoModal';
import style from './style.module.sass';

const Footer = ({ lang }) => {
    const location = useLocation();
    const [legalContent, setLegalContent] = useState('');
    const [legalPath, setLegalPath] = useState('');

    const legalModals = [
        { message: 'contacts', value: 'contacts' },
        { message: 'cookies_policy', value: 'cookies' },
        { message: 'privacy_policy', value: 'privacy' },
        { message: 'terms', value: 'terms' },
    ];

    useEffect(() => {
        if (legalPath) {
            fetch(legalPath)
                .then((response) => {
                    if (response.ok) {
                        // Patikrina, ar atsakymo statusas yra sėkmingas (200-299)
                        return response.text();
                    }
                    throw new Error(
                        'Network response was not ok: ' + legalPath
                    );
                })
                .then((result) => {
                    setLegalContent(result);
                    setLegalPath('');
                })
                .catch((error) => console.error('Fetch error:', error));
        }
    }, [legalPath]);

    const onCloseModal = () => {
        setLegalContent('');
        setLegalPath('');
    };

    if (location.pathname === '/play') return null;

    const handleLegalClick = (doc) => () => {
        const termsConfigKey = `${doc}URL_${lang}`;
        if (typeof config[termsConfigKey] === "undefined") {
            return false;
        }

        const termsURL = config[termsConfigKey];
        setLegalPath(termsURL);
    };

    return (
        <footer className={style.container}>
            <div className={style.footerLine} />
            <div className={style.socialLinks}>
                <a href="https://www.facebook.com/cgates.lt">
                    <img src={FacebookIcon} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/cgates_lt/">
                    <img src={InstagramIcon} alt="Instagram" />
                </a>
                <a href="https://cgates.lt" className={style.textLink}>
                    {'cgates.lt'}
                </a>
            </div>
            <div className={style.legalLinks}>
                {legalModals.map((item) => (
                    <button
                        key={item.message}
                        type="button"
                        onClick={handleLegalClick(item.value)}
                        className={style.legalButton}
                    >
                        <FormattedMessage id={item.message} />
                    </button>
                ))}
            </div>
            <div className={style.footerBottom}>{'© UAB „Cgates“'}</div>
            <LegalInfoModal content={legalContent} onClose={onCloseModal} />
        </footer>
    );
};

export default Footer;
