import React from 'react';
import BasicModal from 'components/elements/BasicModal';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import style from './style.module.sass';

const LegalInfoModal = ({ content, onClose }) => {
    return (
        <BasicModal show={content} onClose={onClose} className={style.legalModal}>
            <div className={style.legalInfoWrapper}>
                <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
            </div>
        </BasicModal>
    );
};

export default LegalInfoModal;
